import revive_payload_client_ELthXzuboF from "/__w/locokit/locokit/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.14.2_encoding@0.1.13_eslint@8.57.0_ioredis@5_7zco5as2rfao36d7fyg65jo5ke/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_92QMRDQo1a from "/__w/locokit/locokit/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.14.2_encoding@0.1.13_eslint@8.57.0_ioredis@5_7zco5as2rfao36d7fyg65jo5ke/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_hdrZaXaUpm from "/__w/locokit/locokit/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.14.2_encoding@0.1.13_eslint@8.57.0_ioredis@5_7zco5as2rfao36d7fyg65jo5ke/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_qz0E3a8Pw7 from "/__w/locokit/locokit/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.14.2_encoding@0.1.13_eslint@8.57.0_ioredis@5_7zco5as2rfao36d7fyg65jo5ke/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_Y7MKGn7ZHT from "/__w/locokit/locokit/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.14.2_encoding@0.1.13_eslint@8.57.0_ioredis@5_7zco5as2rfao36d7fyg65jo5ke/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_wB8405xlFI from "/__w/locokit/locokit/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.14.2_encoding@0.1.13_eslint@8.57.0_ioredis@5_7zco5as2rfao36d7fyg65jo5ke/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_ZXRO3dHp1y from "/__w/locokit/locokit/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.14.2_encoding@0.1.13_eslint@8.57.0_ioredis@5_7zco5as2rfao36d7fyg65jo5ke/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_JsBI0l7Zlz from "/__w/locokit/locokit/node_modules/.pnpm/@pinia+nuxt@0.5.1_magicast@0.3.5_rollup@4.21.2_typescript@5.4.5_vue@3.5.12_typescript@5.4.5__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/__w/locokit/locokit/packages/nuxt-locokit/playground/.nuxt/components.plugin.mjs";
import prefetch_client_kTtGdH40bi from "/__w/locokit/locokit/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.14.2_encoding@0.1.13_eslint@8.57.0_ioredis@5_7zco5as2rfao36d7fyg65jo5ke/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import _6_middlewares_15uNakrgOy from "/__w/locokit/locokit/packages/nuxt-locokit/src/runtime/plugins/6_middlewares.ts";
import _5_vee_validate_RKLw6vvvSL from "/__w/locokit/locokit/packages/nuxt-locokit/src/runtime/plugins/5_vee-validate.ts";
import _3_i18n_WH6lZYC9B9 from "/__w/locokit/locokit/packages/nuxt-locokit/src/runtime/plugins/3_i18n.ts";
import _2_directive_ZENN733d4x from "/__w/locokit/locokit/packages/nuxt-locokit/src/runtime/plugins/2_directive.ts";
import _1_error_AAMB8iXHMY from "/__w/locokit/locokit/packages/nuxt-locokit/src/runtime/plugins/1_error.ts";
import primevue_plugin_egKpok8Auk from "/__w/locokit/locokit/packages/nuxt-locokit/playground/.nuxt/primevue-plugin.mjs";
import plugin_client_OTFLSEInAx from "/__w/locokit/locokit/node_modules/.pnpm/@primevue+nuxt-module@4.1.1_@babel+parser@7.26.2_magicast@0.3.5_rollup@4.21.2_vue@3.5.12_type_2ocu26linmknotxjyi5rggpmie/node_modules/@primevue/nuxt-module/dist/runtime/plugin.client.js";
export default [
  revive_payload_client_ELthXzuboF,
  unhead_92QMRDQo1a,
  router_hdrZaXaUpm,
  payload_client_qz0E3a8Pw7,
  navigation_repaint_client_Y7MKGn7ZHT,
  check_outdated_build_client_wB8405xlFI,
  chunk_reload_client_ZXRO3dHp1y,
  plugin_vue3_JsBI0l7Zlz,
  components_plugin_KR1HBZs4kY,
  prefetch_client_kTtGdH40bi,
  _6_middlewares_15uNakrgOy,
  _5_vee_validate_RKLw6vvvSL,
  _3_i18n_WH6lZYC9B9,
  _2_directive_ZENN733d4x,
  _1_error_AAMB8iXHMY,
  primevue_plugin_egKpok8Auk,
  plugin_client_OTFLSEInAx
]