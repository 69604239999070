import { default as DashboardPageTv2OQs1s77Meta } from "/__w/locokit/locokit/packages/nuxt-locokit/src/runtime/pages/workspace/DashboardPage/DashboardPage.vue?macro=true";
import { default as AboutDatasourceiUEnLUzw1bMeta } from "/__w/locokit/locokit/packages/nuxt-locokit/src/runtime/pages/workspace/datasource/AboutDatasource/AboutDatasource.vue?macro=true";
import { default as CreateDatasourceZAMYlYDklHMeta } from "/__w/locokit/locokit/packages/nuxt-locokit/src/runtime/pages/workspace/datasource/CreateDatasource/CreateDatasource.vue?macro=true";
import { default as UpdateDatasourceFNpdMEj93GMeta } from "/__w/locokit/locokit/packages/nuxt-locokit/src/runtime/pages/workspace/datasource/UpdateDatasource/UpdateDatasource.vue?macro=true";
import { default as SchemaDatasourcedsdf4ybOB0Meta } from "/__w/locokit/locokit/packages/nuxt-locokit/src/runtime/pages/workspace/datasource/SchemaDatasource/SchemaDatasource.vue?macro=true";
import { default as RecordTable9bVe6WeVsjMeta } from "/__w/locokit/locokit/packages/nuxt-locokit/src/runtime/pages/workspace/datasource/RecordTable/RecordTable.vue?macro=true";
import { default as CreateTablekSxCqnnOFMMeta } from "/__w/locokit/locokit/packages/nuxt-locokit/src/runtime/pages/workspace/datasource/CreateTable/CreateTable.vue?macro=true";
import { default as UpdateTablerKjmULEm51Meta } from "/__w/locokit/locokit/packages/nuxt-locokit/src/runtime/pages/workspace/datasource/UpdateTable/UpdateTable.vue?macro=true";
import { default as indexc7iKRvjZyTMeta } from "/__w/locokit/locokit/packages/nuxt-locokit/src/runtime/pages/workspace/datasource/index.vue?macro=true";
import { default as SettingsWorkspaceRlF5GC6ZpdMeta } from "/__w/locokit/locokit/packages/nuxt-locokit/src/runtime/pages/workspace/SettingsWorkspace/SettingsWorkspace.vue?macro=true";
import { default as indexLxA6YkEh77Meta } from "/__w/locokit/locokit/packages/nuxt-locokit/src/runtime/pages/workspace/index.vue?macro=true";
import { default as AboutUsers1Vcj2LN8ddMeta } from "/__w/locokit/locokit/packages/nuxt-locokit/src/runtime/pages/admin/users/AboutUsers/AboutUsers.vue?macro=true";
import { default as CreateUserwp1cnYHfv2Meta } from "/__w/locokit/locokit/packages/nuxt-locokit/src/runtime/pages/admin/users/CreateUser/CreateUser.vue?macro=true";
import { default as RecordUserkTDWL1axKWMeta } from "/__w/locokit/locokit/packages/nuxt-locokit/src/runtime/pages/admin/users/RecordUser/RecordUser.vue?macro=true";
import { default as indexqRq4RBPmQGMeta } from "/__w/locokit/locokit/packages/nuxt-locokit/src/runtime/pages/admin/users/index.vue?macro=true";
import { default as AboutGroupsdYOfSthboyMeta } from "/__w/locokit/locokit/packages/nuxt-locokit/src/runtime/pages/admin/groups/AboutGroups/AboutGroups.vue?macro=true";
import { default as CreateGroup4MU2IESKYUMeta } from "/__w/locokit/locokit/packages/nuxt-locokit/src/runtime/pages/admin/groups/CreateGroup/CreateGroup.vue?macro=true";
import { default as RecordGrouphwOyBdE9lLMeta } from "/__w/locokit/locokit/packages/nuxt-locokit/src/runtime/pages/admin/groups/RecordGroup/RecordGroup.vue?macro=true";
import { default as indexY7I0O0uYRiMeta } from "/__w/locokit/locokit/packages/nuxt-locokit/src/runtime/pages/admin/groups/index.vue?macro=true";
import { default as indexvrcnGdzzLLMeta } from "/__w/locokit/locokit/packages/nuxt-locokit/src/runtime/pages/admin/index.vue?macro=true";
import { default as UpdateGeneralSVX2bJBbX4Meta } from "/__w/locokit/locokit/packages/nuxt-locokit/src/runtime/pages/profile/UpdateGeneral/UpdateGeneral.vue?macro=true";
import { default as UpdateEmailfPXfi3nka0Meta } from "/__w/locokit/locokit/packages/nuxt-locokit/src/runtime/pages/profile/UpdateEmail/UpdateEmail.vue?macro=true";
import { default as UpdatePasswordGOaLEdIZhBMeta } from "/__w/locokit/locokit/packages/nuxt-locokit/src/runtime/pages/profile/UpdatePassword/UpdatePassword.vue?macro=true";
import { default as index3825mZIkVZMeta } from "/__w/locokit/locokit/packages/nuxt-locokit/src/runtime/pages/profile/index.vue?macro=true";
import { default as WorkspacesList4iMWAWVfwWMeta } from "/__w/locokit/locokit/packages/nuxt-locokit/src/runtime/pages/WorkspacesList/WorkspacesList.vue?macro=true";
import { default as CreateWorkspaceqoXC3w8mrLMeta } from "/__w/locokit/locokit/packages/nuxt-locokit/src/runtime/pages/CreateWorkspace/CreateWorkspace.vue?macro=true";
import { default as indexe2SQJbKTkSMeta } from "/__w/locokit/locokit/packages/nuxt-locokit/src/runtime/pages/index.vue?macro=true";
import { default as SignInu0mtwNLKWVMeta } from "/__w/locokit/locokit/packages/nuxt-locokit/src/runtime/pages/auth/SignIn.vue?macro=true";
import { default as LostPasswordLjrvIAVAEKMeta } from "/__w/locokit/locokit/packages/nuxt-locokit/src/runtime/pages/auth/LostPassword.vue?macro=true";
import { default as VerifySignupk5IidbFERCMeta } from "/__w/locokit/locokit/packages/nuxt-locokit/src/runtime/pages/auth/VerifySignup.vue?macro=true";
import { default as ResetPassword9lcwljLKg2Meta } from "/__w/locokit/locokit/packages/nuxt-locokit/src/runtime/pages/auth/ResetPassword.vue?macro=true";
import { default as SignUpER9TkxPhOMMeta } from "/__w/locokit/locokit/packages/nuxt-locokit/src/runtime/pages/auth/SignUp.vue?macro=true";
import { default as AlreadyAuthenticatedzWT0QC1i93Meta } from "/__w/locokit/locokit/packages/nuxt-locokit/src/runtime/pages/auth/AlreadyAuthenticated.vue?macro=true";
import { default as ConfirmUpdateEmail7PMpmTxWaRMeta } from "/__w/locokit/locokit/packages/nuxt-locokit/src/runtime/pages/auth/ConfirmUpdateEmail.vue?macro=true";
export default [
  {
    name: "Workspace",
    path: "/w/:workspaceSlug",
    meta: { ...(indexLxA6YkEh77Meta || {}), ...{"protected":true} },
    redirect: indexLxA6YkEh77Meta?.redirect,
    component: () => import("/__w/locokit/locokit/packages/nuxt-locokit/src/runtime/pages/workspace/index.vue"),
    children: [
  {
    name: "Dashboard",
    path: "/w/:workspaceSlug/dashboard",
    meta: {"protected":true},
    component: () => import("/__w/locokit/locokit/packages/nuxt-locokit/src/runtime/pages/workspace/DashboardPage/DashboardPage.vue")
  },
  {
    name: "Datasource",
    path: "/w/:workspaceSlug/ds",
    meta: { ...(indexc7iKRvjZyTMeta || {}), ...{"protected":true} },
    redirect: indexc7iKRvjZyTMeta?.redirect,
    component: () => import("/__w/locokit/locokit/packages/nuxt-locokit/src/runtime/pages/workspace/datasource/index.vue"),
    children: [
  {
    name: "AboutDatasource",
    path: "/w/:workspaceSlug/ds/about",
    meta: {"protected":true},
    component: () => import("/__w/locokit/locokit/packages/nuxt-locokit/src/runtime/pages/workspace/datasource/AboutDatasource/AboutDatasource.vue")
  },
  {
    name: "CreateDatasource",
    path: "/w/:workspaceSlug/ds/create",
    meta: {"protected":true},
    component: () => import("/__w/locokit/locokit/packages/nuxt-locokit/src/runtime/pages/workspace/datasource/CreateDatasource/CreateDatasource.vue")
  },
  {
    name: "UpdateDatasource",
    path: "/w/:workspaceSlug/ds/:datasourceSlug",
    meta: {"protected":true},
    component: () => import("/__w/locokit/locokit/packages/nuxt-locokit/src/runtime/pages/workspace/datasource/UpdateDatasource/UpdateDatasource.vue")
  },
  {
    name: "SchemaDatasource",
    path: "/w/:workspaceSlug/ds/:datasourceSlug/schema",
    meta: {"protected":true},
    component: () => import("/__w/locokit/locokit/packages/nuxt-locokit/src/runtime/pages/workspace/datasource/SchemaDatasource/SchemaDatasource.vue")
  },
  {
    name: "RecordTable",
    path: "/w/:workspaceSlug/ds/:datasourceSlug/table/:tableSlug",
    meta: {"protected":true},
    component: () => import("/__w/locokit/locokit/packages/nuxt-locokit/src/runtime/pages/workspace/datasource/RecordTable/RecordTable.vue")
  },
  {
    name: "CreateTable",
    path: "/w/:workspaceSlug/ds/:datasourceSlug/table/create",
    meta: {"protected":true},
    component: () => import("/__w/locokit/locokit/packages/nuxt-locokit/src/runtime/pages/workspace/datasource/CreateTable/CreateTable.vue")
  },
  {
    name: "UpdateTable",
    path: "/w/:workspaceSlug/ds/:datasourceSlug/table/:tableSlug/update",
    meta: {"protected":true},
    component: () => import("/__w/locokit/locokit/packages/nuxt-locokit/src/runtime/pages/workspace/datasource/UpdateTable/UpdateTable.vue")
  }
]
  },
  {
    name: "Settings",
    path: "/w/:workspaceSlug/settings",
    meta: {"protected":true},
    component: () => import("/__w/locokit/locokit/packages/nuxt-locokit/src/runtime/pages/workspace/SettingsWorkspace/SettingsWorkspace.vue")
  }
]
  },
  {
    name: "Admin",
    path: "/admin",
    meta: {"protected":true},
    redirect: "/admin/users",
    component: () => import("/__w/locokit/locokit/packages/nuxt-locokit/src/runtime/pages/admin/index.vue"),
    children: [
  {
    name: "Users",
    path: "/admin/users",
    meta: {"protected":true},
    redirect: "/admin/users/about",
    component: () => import("/__w/locokit/locokit/packages/nuxt-locokit/src/runtime/pages/admin/users/index.vue"),
    children: [
  {
    name: "AboutUsers",
    path: "/admin/users/about",
    meta: {"protected":true},
    component: () => import("/__w/locokit/locokit/packages/nuxt-locokit/src/runtime/pages/admin/users/AboutUsers/AboutUsers.vue")
  },
  {
    name: "CreateUser",
    path: "/admin/users/create-user",
    meta: {"protected":true},
    component: () => import("/__w/locokit/locokit/packages/nuxt-locokit/src/runtime/pages/admin/users/CreateUser/CreateUser.vue")
  },
  {
    name: "RecordUser",
    path: "/admin/users/record/:id",
    meta: {"protected":true},
    component: () => import("/__w/locokit/locokit/packages/nuxt-locokit/src/runtime/pages/admin/users/RecordUser/RecordUser.vue")
  }
]
  },
  {
    name: "Groups",
    path: "/admin/groups",
    meta: {"protected":true},
    redirect: "/admin/groups/about",
    component: () => import("/__w/locokit/locokit/packages/nuxt-locokit/src/runtime/pages/admin/groups/index.vue"),
    children: [
  {
    name: "AboutGroups",
    path: "/admin/groups/about",
    meta: {"protected":true},
    component: () => import("/__w/locokit/locokit/packages/nuxt-locokit/src/runtime/pages/admin/groups/AboutGroups/AboutGroups.vue")
  },
  {
    name: "CreateGroup",
    path: "/admin/groups/create-group",
    meta: {"protected":true},
    component: () => import("/__w/locokit/locokit/packages/nuxt-locokit/src/runtime/pages/admin/groups/CreateGroup/CreateGroup.vue")
  },
  {
    name: "RecordGroup",
    path: "/admin/groups/record/:id",
    meta: {"protected":true},
    component: () => import("/__w/locokit/locokit/packages/nuxt-locokit/src/runtime/pages/admin/groups/RecordGroup/RecordGroup.vue")
  }
]
  }
]
  },
  {
    name: "Profile",
    path: "/profile",
    meta: {"protected":true},
    redirect: "/profile/general",
    component: () => import("/__w/locokit/locokit/packages/nuxt-locokit/src/runtime/pages/profile/index.vue"),
    children: [
  {
    name: "UpdateGeneral",
    path: "/profile/general",
    meta: {"protected":true},
    component: () => import("/__w/locokit/locokit/packages/nuxt-locokit/src/runtime/pages/profile/UpdateGeneral/UpdateGeneral.vue")
  },
  {
    name: "UpdateEmail",
    path: "/profile/email",
    meta: {"protected":true},
    component: () => import("/__w/locokit/locokit/packages/nuxt-locokit/src/runtime/pages/profile/UpdateEmail/UpdateEmail.vue")
  },
  {
    name: "UpdatePassword",
    path: "/profile/password",
    meta: {"protected":true},
    component: () => import("/__w/locokit/locokit/packages/nuxt-locokit/src/runtime/pages/profile/UpdatePassword/UpdatePassword.vue")
  }
]
  },
  {
    name: "Workspaces",
    path: "/w",
    meta: {"protected":false,"anonymous":false},
    component: () => import("/__w/locokit/locokit/packages/nuxt-locokit/src/runtime/pages/WorkspacesList/WorkspacesList.vue")
  },
  {
    name: "CreateWorkspace",
    path: "/w/create",
    meta: {"protected":true,"anonymous":false},
    component: () => import("/__w/locokit/locokit/packages/nuxt-locokit/src/runtime/pages/CreateWorkspace/CreateWorkspace.vue")
  },
  {
    name: "Home",
    path: "/",
    meta: {"protected":false},
    component: () => import("/__w/locokit/locokit/packages/nuxt-locokit/src/runtime/pages/index.vue")
  },
  {
    name: "SignIn",
    path: "/auth/sign-in",
    meta: { ...(SignInu0mtwNLKWVMeta || {}), ...{"anonymous":true,"protected":false} },
    component: () => import("/__w/locokit/locokit/packages/nuxt-locokit/src/runtime/pages/auth/SignIn.vue")
  },
  {
    name: "LostPassword",
    path: "/auth/lost-password",
    meta: { ...(LostPasswordLjrvIAVAEKMeta || {}), ...{"anonymous":true,"protected":false} },
    component: () => import("/__w/locokit/locokit/packages/nuxt-locokit/src/runtime/pages/auth/LostPassword.vue")
  },
  {
    name: "VerifySignup",
    path: "/auth/verify-signup",
    meta: { ...(VerifySignupk5IidbFERCMeta || {}), ...{"anonymous":true,"protected":false} },
    component: () => import("/__w/locokit/locokit/packages/nuxt-locokit/src/runtime/pages/auth/VerifySignup.vue")
  },
  {
    name: "ResetPassword",
    path: "/auth/reset-password",
    meta: { ...(ResetPassword9lcwljLKg2Meta || {}), ...{"anonymous":true,"protected":false} },
    component: () => import("/__w/locokit/locokit/packages/nuxt-locokit/src/runtime/pages/auth/ResetPassword.vue")
  },
  {
    name: "SignUp",
    path: "/auth/sign-up",
    meta: { ...(SignUpER9TkxPhOMMeta || {}), ...{"anonymous":true,"protected":false} },
    component: () => import("/__w/locokit/locokit/packages/nuxt-locokit/src/runtime/pages/auth/SignUp.vue")
  },
  {
    name: "AlreadyAuthenticated",
    path: "/auth/already-authenticated",
    meta: {"anonymous":true,"protected":false},
    component: () => import("/__w/locokit/locokit/packages/nuxt-locokit/src/runtime/pages/auth/AlreadyAuthenticated.vue")
  },
  {
    name: "ConfirmUpdateEmail",
    path: "/auth/confirm-update-email",
    meta: {"anonymous":true,"protected":false},
    component: () => import("/__w/locokit/locokit/packages/nuxt-locokit/src/runtime/pages/auth/ConfirmUpdateEmail.vue")
  }
]